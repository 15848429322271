import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import CalendlyEmbed from "../components/CalendalyEmbed"
import BlueHeader from "../components/BlueHeader"
import Footer from "../components/Footer";
import Workshop from "../components/Workshop";
import { navigate } from "gatsby"


const IndexPage = () => {
  React.useEffect(() => {
    navigate("/itc2024", { replace: true });
  }, []);

  return null;
};

// const IndexPage = () => {

//   return (<Layout>
//     <SEO title="Home" />
//     <div className="flex overflow-x-hidden flex-col items-center main-content">
//       <Workshop />
//       <div className="flex"></div>
//       <div className="w-full nav-line-2"></div>
//       <Footer />
//     </div>

//   </Layout>)
// }

export default IndexPage